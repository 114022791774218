import { Injectable, signal } from '@angular/core';
import { LOCAL_STORAGE } from '../enums/local-storage.enum';

export type ThemeModeType = 'dark' | 'light' | 'system';

@Injectable()
export class ThemeModeService {
  private static readonly THEME_MENU_MODE_LS_KEY =
    LOCAL_STORAGE.THEME_MENU_MODE_LS_KEY;
  private static readonly systemMode: 'light' | 'dark' =
    ThemeModeService.getSystemMode();
  public menuMode = signal<ThemeModeType>(
    ThemeModeService.getThemeModeFromLocalStorage(
      ThemeModeService.THEME_MENU_MODE_LS_KEY,
    ),
  );

  private static getSystemMode(): 'light' | 'dark' {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
  }

  private static themeModeSwitchHelper(mode: ThemeModeType) {
    const resolvedMode = mode !== 'system' ? mode : ThemeModeService.systemMode;
    document.documentElement.setAttribute('data-bs-theme', resolvedMode);
  }

  private static getThemeModeFromLocalStorage(key: string): ThemeModeType {
    const data = localStorage?.getItem(key);
    return data === 'dark' || data === 'system' ? data : 'light';
  }

  private updateLocalStorage(key: string, value: ThemeModeType) {
    localStorage?.setItem(key, value);
  }

  public updateMode(mode: ThemeModeType) {
    const resolvedMode = mode === 'system' ? ThemeModeService.systemMode : mode;
    ThemeModeService.themeModeSwitchHelper(resolvedMode);
  }

  public updateMenuMode(menuMode: ThemeModeType) {
    this.menuMode.set(menuMode);
    this.updateLocalStorage(ThemeModeService.THEME_MENU_MODE_LS_KEY, menuMode);
  }

  public init() {
    this.updateMode(this.menuMode());
    this.updateMenuMode(this.menuMode());
  }

  public switchMode(mode: ThemeModeType) {
    const resolvedMode = mode === 'system' ? ThemeModeService.systemMode : mode;
    this.updateMode(resolvedMode);
    this.updateMenuMode(mode);
  }
}
