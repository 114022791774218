import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeeniconComponent } from '../../../../../../modules/partials/keenicon/keenicon.component';

@Component({
  selector: 'app-sidebar-footer',
  standalone: true,
  imports: [CommonModule, KeeniconComponent],
  templateUrl: './sidebar-footer.component.html',
  styleUrl: './sidebar-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarFooterComponent {}
