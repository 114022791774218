import { Injectable, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { LanguageType } from '../modules/partials/switch-language/data/language-data';
import { LOCAL_STORAGE } from '../enums/local-storage.enum';

@Injectable()
export class SwitchLanguageService {
  currentLang = signal<LanguageType>(
    SwitchLanguageService.retrieveStoredLanguage() as LanguageType,
  );

  constructor(private translocoService: TranslocoService) {
    this.translocoService.setActiveLang(this.currentLang());
  }

  setTranslation(lang: LanguageType) {
    this.translocoService.setActiveLang(lang);
    this.currentLang.set(lang);
    localStorage.setItem(LOCAL_STORAGE.SELECTED_LANGUAGE, lang);
  }

  static getStoredLanguage(): string {
    return SwitchLanguageService.retrieveStoredLanguage();
  }

  private static retrieveStoredLanguage(): string {
    const storedLang = localStorage.getItem(LOCAL_STORAGE.SELECTED_LANGUAGE);
    return storedLang || 'en';
  }
}
