import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { KeeniconComponent } from '../../../../../../modules/partials/keenicon/keenicon.component';
import { PAGE_ROUTES } from '../../../../../../enums/page-routes.enum';
import { TranslocoDirective } from '@jsverse/transloco';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { menuItems } from '../../data/menu-data';

@Component({
  selector: 'app-sidebar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    KeeniconComponent,
    RouterLinkActive,
    TranslocoDirective,
  ],
  templateUrl: './sidebar-menu.component.html',
  styleUrl: './sidebar-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  protected readonly PAGE_ROUTES = PAGE_ROUTES;
  menuItems = menuItems;

  constructor(protected activeOffcanvas: NgbActiveOffcanvas) {}
}
