import {
  Component,
  ChangeDetectionStrategy,
  Renderer2,
  ElementRef,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarLogoComponent } from './partials/sidebar-logo/sidebar-logo.component';
import { SidebarMenuComponent } from './partials/sidebar-menu/sidebar-menu.component';
import { SidebarFooterComponent } from './partials/sidebar-footer/sidebar-footer.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    SidebarLogoComponent,
    SidebarMenuComponent,
    SidebarFooterComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.initializeClasses();
  }

  initializeClasses() {
    const sidebarElement = this.el.nativeElement;
    this.renderer.addClass(sidebarElement, 'app-sidebar');
    this.renderer.addClass(sidebarElement, 'flex-column');
  }
}
