<a
  target="_blank"
  class="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
  data-bs-toggle="tooltip"
  data-bs-trigger="hover"
  data-bs-dismiss-="click"
  title="Metronic Docs & Components"
>
  <span class="btn-label"> Docs & Components </span>
  <app-keenicon name="document" class="fs-2 m-0"></app-keenicon>
</a>
