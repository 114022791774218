import { Component, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeeniconComponent } from '../../../../../../modules/partials/keenicon/keenicon.component';
import { RouterLink } from '@angular/router';
import { LayoutService } from '../../../../../../services/layout.service';

@Component({
  selector: 'app-sidebar-logo',
  standalone: true,
  imports: [CommonModule, KeeniconComponent, RouterLink],
  templateUrl: './sidebar-logo.component.html',
  styleUrl: './sidebar-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLogoComponent {
  constructor(private layoutService: LayoutService) {}

  onSidebarToggleClick() {
    this.layoutService.toggleSidebarMinimize();
  }
}
