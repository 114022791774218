import { Injectable } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from '../pages/auth/modules/sidebar/sidebar.component';

@Injectable()
export class LayoutService {
  private readonly SIDEBAR_MINIMIZE_ATTR = 'data-kt-app-sidebar-minimize';
  private readonly MOBILE_OFFCANVAS_CLASS = 'mobile-sidebar';

  constructor(private offcanvasService: NgbOffcanvas) {}

  toggleSidebarMinimize(): void {
    const bodyElement = document.body;
    const currentState = bodyElement.getAttribute(this.SIDEBAR_MINIMIZE_ATTR);
    const newState = currentState === 'on' ? 'off' : 'on';
    bodyElement.setAttribute(this.SIDEBAR_MINIMIZE_ATTR, newState);
  }

  openMobileMenu() {
    const offcanvasRef = this.offcanvasService.open(SidebarComponent, {
      panelClass: this.MOBILE_OFFCANVAS_CLASS,
    });
    offcanvasRef.componentInstance;
  }
}
