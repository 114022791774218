<div
  id="kt_app_sidebar_menu_scroll"
  class="scroll-y my-5 mx-3"
  data-kt-scroll="true"
  data-kt-scroll-activate="true"
  data-kt-scroll-height="auto"
  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu"
  data-kt-scroll-offset="5px"
  data-kt-scroll-save-state="true"
>
  <div
    *transloco="let t"
    class="menu menu-column menu-rounded menu-sub-indention fw-semibold fs-6"
    id="#kt_app_sidebar_menu"
    data-kt-menu="true"
    data-kt-menu-expand="false"
  >
    <div class="menu-item" *ngFor="let item of menuItems">
      <a
        class="menu-link without-sub"
        [routerLink]="[item.link]"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: item.link === '/' }"
        (click)="activeOffcanvas.close()"
      >
        <span class="menu-icon">
          <app-keenicon [name]="item.icon" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title text-nowrap">{{ t(item.titleKey) }}</span>
      </a>
    </div>
  </div>
</div>
